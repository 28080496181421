import React, {
  createContext,
  ReactNode,
  useMemo,
  useContext,
  useRef,
  useEffect,
  useState,
} from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject, FixedObject } from 'gatsby-image'
import classnames from 'classnames'
import { keyBy, mapValues } from 'lodash'
import ReactPlayer from 'react-player'
import { useLocale } from '../i18n'

const sizes = ['sm', 'md', 'lg'] as const
export type Size = typeof sizes[number]
type ChildImageSharp = { fluid?: FluidObject; fixed?: FixedObject }
type Images = { [K: string]: { [K in Size]?: ChildImageSharp } | undefined }
type Videos = { [K: string]: string }
const ImageContext = createContext<{ images: Images; videos: Videos }>({
  images: {},
  videos: {},
})

export function ImageProvider(props: { children: ReactNode | ReactNode[] }) {
  const data = useStaticQuery(graphql`
    query {
      sm: allFile(filter: { ext: { nin: [".mp4", ".gif", ".svg"] } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fixed(width: 96, height: 64, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      md: allFile(filter: { ext: { nin: [".mp4", ".gif", ".svg"] } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid(maxWidth: 360, maxHeight: 224, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      lg: allFile(filter: { ext: { nin: [".mp4", ".gif", ".svg"] } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid(maxWidth: 688) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      videos: allFile(filter: { ext: { eq: ".mp4" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)

  const images: Images = useMemo(() => {
    const images: Images = {}
    sizes.forEach(size =>
      data[size].edges.forEach(
        ({
          node,
        }: {
          node: {
            name: string
            relativePath: string
            childImageSharp: ChildImageSharp
          }
        }) => {
          images[node.relativePath] = images[node.name] = {
            ...images[node.relativePath],
            [size]: node.childImageSharp,
          }
        }
      )
    )
    return images
  }, [data])

  const videos: Videos = useMemo(
    () => mapValues(keyBy(data.videos.edges, 'node.name'), 'node.publicURL'),
    [data]
  )

  return (
    <ImageContext.Provider
      value={useMemo(() => ({ images, videos }), [images, videos])}
    >
      {props.children}
    </ImageContext.Provider>
  )
}

function Gif(props: {
  className?: string
  src: string
  size?: Size
  height?: number
}) {
  const { src, className, size = 'lg', height } = props
  const videoRef = useRef<HTMLVideoElement>(null)
  const isPlayable = size !== 'sm'
  const [playing, setPlaying] = useState(size === 'lg')
  const [loaded, setLoaded] = useState(false)
  const videoURL = useContext(ImageContext).videos[src.replace('.gif', '.mp4')]

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      if (playing) {
        video.play()
      } else {
        video.pause()
        video.currentTime = 0
      }
    }
  }, [playing])

  return (
    <div
      className={classnames(className, 'flex relative overflow-hidden')}
      onMouseEnter={() => size === 'md' && setPlaying(true)}
      onMouseLeave={() => size === 'md' && setPlaying(false)}
    >
      <Image
        className="w-full"
        src={src.replace('.gif', '.jpg')}
        size={size}
        height={height}
      />
      {playing && (
        <video
          ref={videoRef}
          className={'absolute top-0 w-full h-full object-cover'}
          src={videoURL}
          loop
          muted
          onCanPlayThrough={() => setLoaded(true)}
        />
      )}
      {isPlayable && (
        <div
          className={classnames(
            { 'opacity-0': playing && loaded },
            'font-mono transition-opacity duration-200 absolute top-0 right-0 text-gray-600 border text-xs m-2 px-1 bg-white rounded shadow'
          )}
        >
          {playing ? '...' : 'GIF'}
        </div>
      )}
    </div>
  )
}

export default function Image(props: {
  className?: string
  src: string
  size?: Size
  height?: number
}) {
  const locale = useLocale()
  const { src, className, size = 'lg', height } = props
  const images = useContext(ImageContext).images
  const image = images[src.replace('.', `-${locale}.`)] || images[src]
  const { fluid, fixed } = image?.[size] || {}

  if (src.indexOf('hacer_Animaci') !== -1) {
    console.log(fluid, fixed)
  }
  if (fluid || fixed) {
    return (
      <Img
        className={className}
        style={
          fluid && height !== undefined
            ? { width: height * fluid.aspectRatio }
            : {}
        }
        fixed={fixed}
        fluid={fluid}
      />
    )
  }

  if (src.indexOf('.gif') !== -1) {
    return <Gif className={className} src={src} size={size} height={height} />
  }

  return null
}

export function Video(props: {
  src: string
  autoPlay?: boolean
  controls?: boolean
  loop?: boolean
}) {
  const { src, controls = true, loop, autoPlay } = props
  const videoURL = useContext(ImageContext).videos[src]

  return videoURL ? (
    <div className="flex relative overflow-hidden">
      <video
        className="w-full h-full object-cover sm:rounded-lg outline-none"
        src={videoURL}
        autoPlay={autoPlay}
        controls={controls}
        loop={loop}
      />
    </div>
  ) : (
    <ReactPlayer className="sm:rounded-lg overflow-hidden" url={src} />
  )
}
