import React from 'react'
import SocialIcon from './SocialIcon'

export default function SocialIcons() {
  return (
    <>
      <SocialIcon
        url="mailto:noemivillegas@hotmail.es"
        image={require('../images/correo-electronico.svg')}
        alt="correo"
      />
      <SocialIcon
        url="https://wa.me/+34671320072"
        image={require('../images/whatsapp.svg')}
        alt="whatsapp"
      />

      <SocialIcon
        url="https://www.linkedin.com/in/noemi-villegas-calonge/"
        image={require('../images/linkedin.svg')}
        alt="linkedin"
      />
      <SocialIcon
        url="https://www.instagram.com/_bestiary_design/?hl=es"
        image={require('../images/instagram.svg')}
        alt="instagram"
      />
      <SocialIcon
        url="https://dribbble.com/NOEMI_BD"
        image={require('../images/dribbble.svg')}
        alt="dribbble"
      />
    </>
  )
}
