import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

export default () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const listener = () => setShow(window.scrollY > 500)
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [])

  return (
    <button
      className={classNames(
        { 'opacity-0': !show },
        'bg-white flex items-center justify-center text-xl mr-4 mb-4 fixed right-0 bottom-0 focus:outline-none focus:shadow-outline h-10 w-10 mx-1 inline-flex justify-center bg-transparent hover:bg-teal-500 text-teal-500 font-semibold hover:text-white py-2 px-1 border border-white-500 hover:border-transparent transition duration-200 ease-in-out rounded-full'
      )}
      disabled={!show}
      onClick={() => window.scrollTo({ top: 0 })}
    >
      ☝
    </button>
  )
}
