import React from 'react'
import Modal from './Modal'
import SocialIcons from './SocialIcons'
import { Translate, useLocale } from '../i18n'
import Face from '../components/Face'

export default function AboutMeModalContent(props: {
  isOpen: boolean
  onRequestClose: () => void
}) {
  const locale = useLocale()

  return (
    <Modal
      isOpen={props.isOpen}
      title={'Sobre mí'}
      onRequestClose={props.onRequestClose}
    >
      <div className="header flex justify-center items-center w-full max-w-screen-lg">
        <div className="cara sm:mr-10 flex-shrink-0 flex items-center justify-center sm:justify-end">
          <Face className="h-32 w-32 object-cover rounded-full shadow-sm" />
        </div>
      </div>
      <div>
        <p className="texto1 mt-4">
          ¡Hola! Mi nombre es Noemí. Soy diseñadora multidisciplinar,
          especializada en el ámbito digital. Empecé estudiando Arquitectura
          efímera y he terminando estudiando desarrollo web. ¿Cómo he llegado
          aquí? Actualmente, trabajo como freelance para diferentes clientes
          ofreciendo los siguientes servicios:
        </p>
        <ul>
          <li>
            ·Diseño gráfico (Diseño de marca, cartelería, dossier, tarjetas de
            visita, branding, etc)
          </li>
          <li>
            ·Diseño digital (Contenido para redes, firma mail, emailing...)
          </li>
          <li>·Maquetación Web (Wordpress, html + css + sass)</li>
          <li>
            ·Desarrollo web (Html + css + sass + javascript, mantenimiento web,
            landing...)
          </li>
          <li>·Diseño UX/UI</li>
          <li>·Animación y microinteracciones.</li>
        </ul>
      </div>
    </Modal>
  )
}
