import React, { useEffect, useState } from 'react'
import { toArray } from 'lodash'

export default function WordCarousel({
  className,
  words,
}: {
  className?: string
  words: string[]
}) {
  const length = (word: string) => toArray(word).length
  const [wordIndex, setWordIndex] = useState(0)
  const [charIndex, setCharIndex] = useState(length(words[wordIndex]))
  const [removing, setRemoving] = useState(false)
  const [typing, setTyping] = useState(false)
  const typeTimeout = () => 50 + Math.floor(Math.random() * 100)

  useEffect(() => {
    if (!removing && charIndex === length(words[wordIndex])) {
      setTyping(false)
      const timeout = setTimeout(() => {
        setRemoving(true)
      }, 2000)
      return () => clearTimeout(timeout)
    }
    if (removing && charIndex === 0) {
      const timeout = setTimeout(() => {
        setWordIndex(i => (i + 1) % words.length)
        setRemoving(false)
        setTyping(true)
      }, 500)
      return () => clearTimeout(timeout)
    }
    if (removing) {
      const timeout = setTimeout(
        () => setCharIndex(i => i - 1),
        typeTimeout() / 2
      )
      return () => clearTimeout(timeout)
    }
    if (typing) {
      const timeout = setTimeout(() => setCharIndex(i => i + 1), typeTimeout())
      return () => clearTimeout(timeout)
    }
  }, [removing, typing, words, wordIndex, charIndex])

  return (
    <span className={className}>
      {toArray(words[wordIndex])
        .slice(0, charIndex)
        .join('')}
      <span className="blink font-light">|</span>
    </span>
  )
}
