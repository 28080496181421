import React, { ReactNode } from 'react'
import classNames from 'classnames'

export default function Tag(props: {
  children: ReactNode | ReactNode[]
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}) {
  return (
    <button
      className={classNames(
        {
          'opacity-25': !props.active,
        },
        'inline-block focus:outline-none focus:shadow-outline bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold text-white m-2 transition duration-200 ease-in-out'
      )}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
