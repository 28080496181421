import { Match } from '@reach/router'
import { useNavigate, Translate, useLocale } from '../i18n'
import { flatten } from 'lodash'
import React, { useState } from 'react'
import AboutMeModal from '../components/AboutMeModal'
import Face from '../components/Face'
import Page from '../components/Page'
import Project from '../components/Project'
import ProjectModal from '../components/ProjectModal'
import ScrollButton from '../components/ScrollButton'
import SocialIcons from '../components/SocialIcons'
import Tag from '../components/Tag'
import WordCarousel from '../components/WordCarousel'
import '../css/main.css'
import projects, { slug } from '../projects'
import { useTranslation } from 'react-i18next'

const tags = Array.from(new Set(flatten(projects.map(project => project.tags))))

function Portfolio() {
  const [enabledTags, setEnabledTags] = useState<string[]>([])
  const navigate = useNavigate()
  const [readMore, setReadMore] = useState(false)
  const [t] = useTranslation()
  const locale = useLocale()
  return (
    <>
      <div className="top-bar sm:fixed shadow-sm w-full z-10 flex items-center justify-center">
        <div className="sm:h-20 flex flex-col sm:flex-row items-center p-4 w-full max-w-screen-xl">
          <img
            className="h-10"
            src={require('../images/Recurso 10.png')}
            alt="Logo de Noemi Villegas"
          />

          <div className="Social flex items-center justify-end flex-grow">
            <SocialIcons />
          </div>
          <div className="flex justify-end flex-grow:0; p-4">
            <a
              className="hover:text-teal-300 text-teal-500 font-bold py-2 px-2"
              href="/"
            >
              Esp
            </a>
            <a
              className="hover:text-teal-300  text-teal-500 font-bold py-2 px-2"
              href="/en/"
            >
              En
            </a>
          </div>
        </div>
      </div>
      <nav className="sm:mt-20 h-64 p-4 flex justify-center">
        <div className="header flex items-center w-full max-w-screen-lg">
          <div className="intro flex flex-col flex-grow-1">
            <span className="font-normal text-black text-2xl text-center sm:text-left sm:text-4xl tracking-tight">
              <Translate>¡Hola! Soy</Translate>{' '}
              <WordCarousel
                className="font-semibold"
                words={[
                  'Noemí',
                  'manchega 🧀',
                  'diseñadora UX/UI 💻',
                  'diseñadora gráfica 🎨',
                  'maquetadora web',
                  'apasionada de la animación ❤️',
                ].map(text => t(text))}
              />
            </span>

            <div>
              <p className="texto1 text-teal-800 font-medium mt-5 max-w-screen-sm">
                Bienvenid@ a mi portfolio, mi nombre es Noemí,{' '}
                <b>diseñadora visual especializada en producto digital,</b> con
                base en Madrid y más de 6 años de experiencia.
              </p>

              {/* <div className="text-center sm:text-left">
                <a
                  className="focus:outline-none focus:shadow-outline h-10 w-40 mx-1 inline-flex justify-center bg-transparent hover:bg-teal-500 text-gray-700 font-semibold hover:text-white py-2 px-1 border border-white-500 hover:border-transparent transition duration-200 ease-in-out rounded-full mt-5"
                  onClick={() => setReadMore(!readMore)}
                  target="__blank"
                >
                  <Translate>Sobre mí</Translate>
                </a>
                
              </div> */}
            </div>
          </div>
        </div>
      </nav>
      <main className="eggs p-6 flex-grow w-full max-w-full sm:max-w-screen-xl mx-auto mt-12 sm:mt-0 sm:pt-24">
        <div className="flex flex-row flex-wrap justify-center items-center pb-6">
          {tags.map(tag => (
            <Tag
              key={tag}
              active={
                !(enabledTags.length > 0 && enabledTags.indexOf(tag) === -1)
              }
              onClick={() =>
                setEnabledTags(
                  enabledTags.indexOf(tag) !== -1
                    ? enabledTags.filter(enabledTag => enabledTag !== tag)
                    : [tag, ...enabledTags]
                )
              }
            >
              <Translate>{tag}</Translate> (
              {projects.filter(({ tags }) => tags.indexOf(tag) !== -1).length})
            </Tag>
          ))}
          <Tag
            active={enabledTags.length > 0}
            disabled={enabledTags.length === 0}
            onClick={() => setEnabledTags([])}
          >
            <Translate>Todo</Translate> ({projects.length})
          </Tag>
        </div>
        <section className="flex flex-wrap justify-center">
          {projects
            .filter(
              ({ tags }) =>
                enabledTags.length === 0 ||
                tags.some(tag => enabledTags.indexOf(tag) !== -1)
            )
            .map((project, i) => (
              <div key={i} className="sm:px-3 pb-6 box-border w-full max-w-sm">
                <Project className="h-full" project={project} />
              </div>
            ))}
        </section>
      </main>
      <footer className="flex justify-center w-full bg-gray-800 px-12 py-6 text-white">
        <div className="max-w-screen-lg mx-auto">
          <Translate>Con</Translate> ❤️ Noemi Villegas ©{' '}
          {new Date().getFullYear()}
        </div>
      </footer>
      <ScrollButton />
      <Match<{ slug: string }> path=":slug">
        {({ match }) => (
          <ProjectModal
            project={projects.find(project => match?.slug === slug(project))}
            onRequestClose={() => navigate('/')}
          />
        )}
      </Match>
      <AboutMeModal
        isOpen={readMore}
        onRequestClose={() => setReadMore(false)}
      />
    </>
  )
}

export default function Index() {
  return (
    <Page title="Portfolio">
      <Portfolio />
    </Page>
  )
}
