import React from 'react'

export default function Browser(props: { url: string }) {
  return (
    <div className="w-full sm:px-2 sm:pb-2 sm:rounded-lg bg-gray-400 flex flex-col items-center justify-center">
      <div className="p-3 w-full flex items-center justify-center">
        <a
          className="text-center text-sm text-gray-700 w-full max-w-full truncate sm:max-w-md p-1 rounded bg-gray-100"
          href={props.url}
          target="__blank"
        >
          {props.url}
        </a>
      </div>
      <iframe
        className="bg-white w-full sm:rounded"
        style={{ height: '900px' }}
        src={props.url}
      />
    </div>
  )
}
