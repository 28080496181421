import React, { useState, useEffect } from 'react'
import { Project } from '../projects'
import Modal from './Modal'
import Gallery from './Gallery'
import Tag from './Tag'
import { Translate } from '../i18n'

export default function ProjectModalContent(props: {
  project: Project | undefined
  onRequestClose: () => void
}) {
  const [project, setProject] = useState(props.project)
  const [isOpen, setIsOpen] = useState(!!project)
  useEffect(() => {
    if (props.project) {
      setIsOpen(true)
      setProject(props.project)
    } else {
      setIsOpen(false)
    }
  }, [props.project])
  return (
    <Modal
      isOpen={isOpen}
      title={project?.title}
      onRequestClose={props.onRequestClose}
    >
      {project && (
        <>
          {!project.hideTitle && (
            <h2 className="px-4 sm:px-0 text-2xl font-semibold mb-2">
              {project.title}
            </h2>
          )}
          <div className="mb-8 px-4 sm:px-0">
            {project.content?.() || (
              <Translate>{project.description}</Translate>
            )}
          </div>
          <Gallery
            urls={project.resources || [project.thumbnail]}
            scroll={project.scroll}
          />
          {project.software?.map((software, i) => (
            <Tag key={i} active disabled>
              {software}
            </Tag>
          ))}
        </>
      )}
    </Modal>
  )
}
