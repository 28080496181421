import React, { ReactNode, useState, useLayoutEffect } from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#___gatsby')

export default function Modal({
  children,
  isOpen,
  title,
  onRequestClose,
}: {
  children: ReactNode | ReactNode[]
  isOpen: boolean
  title?: string
  onRequestClose: () => void
}) {
  const [overlay, setOverlay] = useState<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (overlay) {
      setTimeout(() => {
        overlay.scrollTop = 0
      }, 0)
    }
  }, [overlay])

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={title}
      onRequestClose={onRequestClose}
      overlayClassName="sm:p-4 sm:px-6 fixed inset-0 overflow-auto z-20"
      className="flex flex-col mx-auto sm:mb-16 sm:mt-24 max-w-screen-md min-h-full sm:min-h-0 outline-none"
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={200}
      overlayRef={setOverlay}
    >
      <button
        className="sm:hidden leading-none px-6 py-4 text-right text-white text-3xl outline-none"
        onClick={onRequestClose}
      >
        ×
      </button>
      <main className="flex-1 bg-white sm:rounded-lg py-4 sm:p-10 shadow">
        {children}
      </main>
    </ReactModal>
  )
}
