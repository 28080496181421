import React from 'react'

export default function SocialIcon(props: {
  url: string
  image: string
  alt: string
}) {
  return (
    <a
      className="ml-2 focus:outline-none focus:shadow-outline rounded-full transition duration-200 ease-in-out"
      href={props.url}
      target="__blank"
    >
      <img className="social" src={props.image} alt={props.alt} />
    </a>
  )
}
