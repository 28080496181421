import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { ImageProvider } from './Image'
import { Router } from '../i18n'

export default function Page({
  title,
  children,
}: {
  title: string
  children: ReactNode | ReactNode[]
}) {
  const metaDescription = 'Portfolio de Noemí Villegas'
  return (
    <ImageProvider>
      <Helmet
        title={title}
        titleTemplate={`%s | Noemí`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `https://noemivillegascalonge.com/portada.png`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]}
      />
      <Router>{children}</Router>
    </ImageProvider>
  )
}
