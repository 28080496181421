import React, { ReactNode } from 'react'
import {
  useLocalization,
  LocalizedLink,
  LocalizedRouter,
} from 'gatsby-theme-i18n'
import {
  Link as ReachLink,
  RouteComponentProps,
  useNavigate as useReachNavigate,
} from '@reach/router'
import { useTranslation } from 'react-i18next'

export type LocalizedString = string | { es: string; en: string }

export function useLocale() {
  const { locale } = useLocalization() as { locale: 'es' | 'en' }
  return locale
}

export const useNavigate = () => {
  const navigate = useReachNavigate()
  const { localizedPath, locale, defaultLang } = useLocalization()
  return (path: string) => navigate(localizedPath(defaultLang, locale, path))
}

export const Translate = (props: { children: LocalizedString }) => {
  const locale = useLocale()
  const [t] = useTranslation()
  return (
    <>
      {typeof props.children === 'string'
        ? t(props.children)
        : props.children[locale]}
    </>
  )
}

export const Link: typeof ReachLink = props => <LocalizedLink {...props} />

const Route = (
  props: RouteComponentProps & { content: ReactNode | ReactNode[] }
) => <>{props.content}</>

export const Router = (props: { children: ReactNode | ReactNode[] }) => (
  <LocalizedRouter basePath="/">
    <Route path="/*" content={props.children} />
  </LocalizedRouter>
)
