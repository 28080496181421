import React from 'react'
import Creepyface from 'react-creepyface'

export default function Face({ className }: { className?: string }) {
  return (
    <Creepyface
      className={className}
      src={require('../cface/serious.jpeg')}
      data-creepyface
      data-src-hover={require('../cface/hover.jpeg')}
      data-src-look-0={require('../cface/0.jpeg')}
      data-src-look-45={require('../cface/45.jpeg')}
      data-src-look-90={require('../cface/90.jpeg')}
      data-src-look-135={require('../cface/135.jpeg')}
      data-src-look-180={require('../cface/180.jpeg')}
      data-src-look-225={require('../cface/225.jpeg')}
      data-src-look-270={require('../cface/270.jpeg')}
      data-src-look-315={require('../cface/315.jpeg')}
    />
  )
}
