import React, { useState, Fragment } from 'react'
import BrainhubeuCarousel from '@brainhubeu/react-carousel'
import classnames from 'classnames'
import Image, { Video } from './Image'
import Player from 'react-player'
import Browser from './Browser'
import { Player as Lottie, Controls } from '@lottiefiles/react-lottie-player'

function Carousel<T>(props: {
  className?: string
  items: T[]
  renderItem: (item: T) => JSX.Element
  renderThumbnail: (item: T) => JSX.Element
}) {
  const [selectedItem, setSelectedItem] = useState(0)
  return (
    <div className={classnames(props.className, 'sm:rounded-lg bg-gray-200')}>
      <div className="sm:pt-4">
        <BrainhubeuCarousel
          addArrowClickHandler
          animationSpeed={200}
          value={selectedItem}
          onChange={i => setSelectedItem(i)}
        >
          {props.items.map((item, i) => (
            <div
              key={i}
              className="w-full sm:px-4 flex items-center justify-center"
            >
              {props.renderItem(item)}
            </div>
          ))}
        </BrainhubeuCarousel>
      </div>
      <div className="px-4 text-center overflow-hidden">
        <ol className="inline-flex items-center max-w-full mx-auto py-4 overflow-x-auto">
          {props.items.map((item, i) => (
            <li key={i}>
              <button
                className={classnames(
                  'focus:outline-none focus:shadow-outline flex flex-shrink-0 mx-1 rounded-lg overflow-hidden',
                  {
                    'shadow-outline': i === selectedItem,
                  }
                )}
                onClick={() => setSelectedItem(i)}
              >
                {props.renderThumbnail(item)}
              </button>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

const isVideo = (url: string) => !!url.match(/youtube|vimeo|\.mp4/)
const isImage = (url: string) => !!url.match(/\.jpg|\.png|\.gif/)

type MediaType = 'image' | 'video' | 'website' | 'lottie'
const getMedia = (url: string): MediaType =>
  isVideo(url)
    ? 'video'
    : isImage(url)
    ? 'image'
    : url.indexOf('lottie') !== -1
    ? 'lottie'
    : 'website'

type Renderer = {
  single: (url: string) => JSX.Element
  carousel: (url: string) => JSX.Element
  thumbnail: (url: string) => JSX.Element
}
const renderers: { [K in MediaType]: Renderer } = {
  image: {
    single: url => <Image className="sm:rounded-lg w-full" src={url} />,
    carousel: url => <Image className="sm:rounded-lg" src={url} height={500} />,
    thumbnail: url => <Image src={url} size="sm" />,
  },
  video: {
    single: url => (
      <div className="flex items-center justify-center">
        <Video src={url} />
      </div>
    ),
    carousel: url => <Video src={url} />,
    thumbnail: url => (
      <div className="rounded-lg overflow-hidden h-full">
        <Player
          style={{ pointerEvents: 'none' }}
          light
          url={url}
          width="6em"
          height="100%"
        />
      </div>
    ),
  },
  website: {
    single: url => <Browser url={url} />,
    carousel: url => <Browser url={url} />,
    thumbnail: () => (
      <img
        src="https://image.flaticon.com/icons/svg/235/235196.svg"
        className="h-full"
      />
    ),
  },
  lottie: {
    single: url => (
      <Lottie autoplay loop src={url} lottieRef={l => l.setSpeed(0.8)}>
        <Controls visible={true} buttons={['play', 'repeat']} />
      </Lottie>
    ),
    carousel: url => (
      <div onMouseDown={e => e.stopPropagation()}>
        <Lottie autoplay loop src={url} lottieRef={l => l.setSpeed(0.8)}>
          <Controls visible={true} buttons={['play', 'repeat']} />
        </Lottie>
      </div>
    ),
    thumbnail: url => (
      <div className="h-16 w-24 flex items-center justify-center">
        <Lottie autoplay={false} src={url} background="#ffffff" />
      </div>
    ),
  },
}

export default function Gallery(props: { urls: string[]; scroll?: boolean }) {
  if (props.urls.length === 1) {
    return renderers[getMedia(props.urls[0])].single(props.urls[0])
  }

  if (props.scroll) {
    return (
      <>
        {props.urls.map((url, i) => (
          <Fragment key={i}>{renderers[getMedia(url)].single(url)}</Fragment>
        ))}
      </>
    )
  }

  return (
    <Carousel
      items={props.urls}
      renderItem={url => renderers[getMedia(url)].carousel(url)}
      renderThumbnail={url => renderers[getMedia(url)].thumbnail(url)}
    />
  )
}
