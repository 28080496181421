import React from 'react'
import Image from './Image'
import { Link } from '../i18n'
import { Project as ProjectData, slug } from '../projects'
import { Translate } from '../i18n'

export default function Project(props: {
  className?: string
  project: ProjectData
}) {
  const { className, project } = props
  return (
    <Link
      to={`/${slug(project)}`}
      className={`${className} bg-white text-left transition-shadow duration-200 hover:shadow-outline focus:outline-none focus:shadow-outline flex flex-col rounded-lg overflow-hidden shadow-lg w-full`}
    >
      <Image src={project.thumbnail} size="md" />
      <div className="flex-grow px-6 py-4">
        <div className="font-bold text-xl mb-2">
          <Translate>{project.title}</Translate>
        </div>
        <p className="text-gray-700 text-base">
          <Translate>{project.description || 'FIX'}</Translate>
        </p>
      </div>
    </Link>
  )
}
